<template>
  <v-alert
    border="right"
    colored-border
    type="error"
    elevation="4"
    dense
    v-if="errors.length > 0"
  >
    <ul v-if="errors.length > 1">
      <li v-for="error in errors" :key="error">
        {{ getDisplayText(error) }}
      </li>
    </ul>
    <span v-else>
      {{ getDisplayText(errors[0]) }}
    </span>
  </v-alert>
</template>

<script>
export default {
  props: {
    /**
     * You can pass in strings or numbers (or both at the same time).
     * Strings will be displayed without tampering.
     * Numbers will be used to grab a proper translation like 'errors.for-code-10001'.
     */
    errors: { type: Array, default: () => [] }
  },
  methods: {
    isNumber (value) {
      return typeof value === 'number'
    },
    getDisplayText (value) {
      if (this.isNumber(value)) {
        return this.$t(`errors.for-code-${value}`)
      }
      return value
    }
  }
}
</script>
