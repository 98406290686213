<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">
        <span v-if="items.length > 1">
          {{ $tc('common.delete-items-title', items.length, { entityNamePlural }) }}
        </span>
        <span v-else>
          {{ $tc('common.delete-items-title', 1, { entityName }) }}
        </span>
      </v-card-title>

      <v-card-text>
        <ErrorsDisplay
          :errors="validationErrorsAsArray"
          class="ma-4"
        />

        <v-alert
          v-if="warning.length > 0"
          border="left"
          colored-border
          type="warning"
          elevation="2"
          class="ma-4"
          icon="mdi-alert"
        >
          {{ warning }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="showDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="error"
          @click="deleteConfirmed"
          :loading="isDeleting"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import ErrorsDisplay from '@/components/ErrorsDisplay'

export default {
  components: { ErrorsDisplay },
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    /**
     * The url at which an item can be deleted. Example: 'users'.
     */
    deleteUrl: { type: String, required: true },
    /**
     * The items to be deleted. These should have an 'id' property.
     */
    items: { type: Array, required: true },
    /**
     * The name for a single entity. Example: 'user'.
     */
    entityName: { type: String, required: true },
    /**
     * The name for multiple entities. Example: 'users'.
     */
    entityNamePlural: { type: String, required: true },
    /**
     * The warning to display in the dialog.
     */
    warning: { type: String, default: '' }
  },
  emits: ['itemDeleted'],
  data () {
    return {
      isDeleting: false,
      validationErrors: {}
    }
  },
  computed: {
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    validationErrorsAsArray () {
      return this.validationErrorsToArray(this.validationErrors)
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    async deleteItem (item) {
      const response = await axios.delete(`${this.deleteUrl}/${item.id}`)
      // Tell the parent which item was deleted.
      this.$emit('itemDeleted', item)
      return response
    },
    async deleteItems () {
      const deleteRequests = []
      for (let i = 0; i < this.items.length; i++) {
        deleteRequests.push(this.deleteItem(this.items[i]))
      }
      await Promise.all(deleteRequests)
    },
    async deleteConfirmed () {
      this.isDeleting = true
      this.validationErrors = {}

      const amountOfItems = this.items.length
      try {
        // Delete all items.
        await this.deleteItems()
        // Show a message indicating the delete succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [
            this.$tc('common.delete-success-message', amountOfItems, {
              entityName: this.entityName,
              entityNamePlural: this.entityNamePlural
            })
          ],
          duration: 5000
        })
        // Close the dialog.
        this.showDialog = false
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.validationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [
              this.$tc('common.delete-failed-message', amountOfItems, {
                entityName: this.entityName,
                entityNamePlural: this.entityNamePlural
              })
            ],
            duration: 5000
          })
        }
      } finally {
        this.isDeleting = false
      }
    },
    validationErrorsToArray (validationErrorObject) {
      return Object.entries(validationErrorObject).reduce((result, [key, errors]) => {
        return result.concat(errors)
      }, [])
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        // Only update the values if the dialog is being opened.
        if (newVal) {
          this.validationErrors = {}
        }
      }
    }
  }
}
</script>
